.dados__contato{
	color: $vermelho;

	.media,
	.col-auto{
		max-width: 100%
	}

	.media{
		display: grid;
		grid-template-columns: min-content 1fr;
	}

	.media-body{
		max-width: 100%;
		align-self: center;
	}

	.fas{
		font-size: 30px;
		margin-right: 15px;
	}
}

.form__contato{

	.form-control{
		border: 1px solid #CCC;
		border-radius: 15px;
		min-height: 48px;
	}

	textarea.form-control{
		min-height: 170px;
	}

	$validation: (
		'success': $success,
		'warning': $warning,
		'error': $danger
	);

	@each $type,$color in $validation{
		.has__#{$type} {
			.form-control{
				border-color: $color;
			}
		}
	}

	@include media-breakpoint-up(md) {
		display: grid;
		grid-template: repeat(2, calc(50% - 15px));
		grid-column-gap: 30px;

		.grid-2{
			grid-column: span 2;
		}
	}
}

.page__contato{
	position: relative;
	z-index: 5;
	margin-bottom: -150px;
}

#mapa-contato{
	position: relative;
	z-index: 0;
	background: #EBE9E5;

	&:before{
		padding-bottom: 207px;
	}
}
