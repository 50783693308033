.btn-pill{
	border-radius: 2rem;
}

.btn-enviar{
	@include button-variant($vermelho, $vermelho);
	border-radius: 15px 0 15px 0;
	position: relative;
	overflow: hidden;
	min-width: 150px;
	font-family: 'montserratbold', sans-serif;

	&:before{
		content: fa-content($fa-var-paper-plane);
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		position: absolute;
		left: 100%;
		top: 0;
		transform: translate(-100%,0);
		display: block;
		line-height: 1;
	}

	&:hover{
		&:before{
			animation: sendPaperPlane 1s linear;
		}
	}
}

@keyframes sendPaperPlane{
	0%{
		left: 0;
		top: 100%;
		transform: translate(0,-100%);
	}

	25%{
		left: 25%;
		top: 30%;
		transform: translate(0,0);
	}

	75%{
		left: 75%;
		top: 75%;
		transform: translate(0,0,);
	}

	100%{
		left: 100%;
		top: 0;
		transform: translate(-100%, 0);
	}
}

.btn-saiba-mais{
	@include button-variant($vermelho, $vermelho);
	border-radius: 0 1rem 0 1rem;
	padding: 0.5rem 2rem;
	position: relative;

	&:after,
	&:before{
		@extend .fas;
		content: fa-content($fa-var-cog);
		position: absolute;
		color: #000;
		pointer-events: none;
	}

	&:after{
		font-size: 30px;
		right: -20px;
		top: 0;
	}

	&:before{
		top: -10px;
		right: 2px;
	}

	&:hover{
		&:before{
			animation: gears 1s linear infinite;
		}
		&:after{
			animation: gears 1s linear infinite reverse;
		}
	}
}

@keyframes gears {
	0%{
		transform: rotate(0deg);
	}

	100%{
		transform: rotate(359deg);
	}
}

.btn-cliente{
	background: #FFF;
	border: 1px solid #CCC;
	padding: 0.5rem 2rem;
	border-radius: 1.5rem;
	width: 100%;
	position: relative;
	text-align: left;
	font-family: 'montserratbold', sans-serif;

	&:before{
		content: fa-content($fa-var-cog);
		@extend .fas;
		position: absolute;
		right: 0;
		top: calc(50% - 25px);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: $vermelho;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFF;
		font-size: 30px;
		opacity: 0;
		pointer-events: none;
		transition: all .3s linear;
	}

	&:hover{
		color: $vermelho;

		&::before{
			opacity: 1;
		}
	}
}
