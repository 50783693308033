@import "_fontfaces";

body{
	font-family: 'montserratregular', sans-serif;
	font-size: 14px;
}


.content__editable{
	@include clearfix;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		font-family: 'montserratbold', sans-serif;
	}

	img{
		max-width: 100%;
		height: auto!important;
	}
}
