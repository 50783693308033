.internas{
	padding-top: 45px;
	&:not(.page__contato){
		padding-bottom: 80px;
	}
}

.page__header{
	margin-bottom: 40px;
	font-family: 'montserratbold', sans-serif;
	font-size: 30px;
}
