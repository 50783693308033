.page__produtos{
	flex-grow: 1;
	background: #DDD;
}

#navProdutos{
	margin-bottom: 60px;

	.owl-item{
		padding: 10px 0;
	}

	padding-right: 58px;
	padding-left: 58px;

	.owl-next,
	.owl-prev{
		position: absolute;
		top: 50%;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -24px;
		perspective: 3px;
		background: none;
		border: none;
		outline: none;

		&:before{
			content:'';
			position: absolute;
			width: 38px;
			height: 38px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			background: #FFF;
			z-index: -1;
			transition: all .3s linear;
		}

		.icon{
			$scale: 16/29;
			transform: scale($scale);
			transition: all .3s linear;
		}

		&:hover{
			&:before{
				background: $vermelho;
			}

			.icon{
				filter: invert(1);
			}
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}
}

.produto__categoria{
	display: flex;
	min-height: 50px;
	align-items: center;
	padding: 0.5rem 2rem;
	border-radius: 50px;
	border:2px solid #CCC;
	background: #FFF;
	position: relative;
	color: #000;
	font-family: 'montserratbold', sans-serif;
	font-size: 16px;
	position: relative;

	&:before{
		content: fa-content($fa-var-cog);
		position: absolute;
		@extend .fas;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #FFF;
		box-shadow: 0 0 5px rgba(#000,.4);
		display: flex;
		align-items: center;
		justify-content: center;
		right: -3px;
		top: 50%;
		margin-top: -25px;
		color: $vermelho;
		opacity: 0;
		transition: all 0.3s linear;
		pointer-events: none;
	}

	&.active{
		background: $vermelho;
		color: #FFF;
		border-color: $vermelho;
	}

	span{
		display: inline-block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
	}

	&:hover{
		text-decoration: none;
	}

	&:hover,
	&.active{
		&:before{
			opacity: 1;
		}
	}
}

.vitrine__produtos{
	width: 100%;

	@include media-breakpoint-only(sm) {
		display: grid;
		grid-template-columns: repeat(2, calc((100% - 30px) / 2));
		grid-column-gap: 30px;
	}

	@include media-breakpoint-only(md) {
		display: grid;
		grid-template-columns: repeat(3, calc((100% - 60px) / 3));
		grid-column-gap: 30px;
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(4, calc((100% - 90px) / 4));
		grid-column-gap: 30px;
	}
}
.segura__vitrine{
	.vitrine__listagem{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		.subcategoria__item{

			&.subcategoria__item--active{
				a{
					background-color: rgba(#000, 0.8);
					color: #fff;
				}
			}
			a{
				display: block;
				padding: 12px 10px 10px 10px;
				border-bottom: 1px solid #000;
				color: #000;
				transition: all 0.3s linear;
				font-family: 'montserratbold';
				background-color: #fff;

				&:hover{
					background-color: rgba(#000, 0.6);
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
	.vitrine__sub{
		border-radius: 10px;
		border: 1px solid #9e9e9e;
		margin-right: auto;
		margin-left: auto;
		width: 100%;

		@include media-breakpoint-up (lg) {
			width: 25%;
			margin-right: 25px;
		}
		@include media-breakpoint-down (md) {
			margin-bottom: 30px;

			.vitrine__listagem{
				max-height: 300px;
				overflow-y: auto;
			}
		}

		.vitrine__header{
			font-family: 'montserratbold';
			background-color: #fd0101;
			color: #fff;
			padding: 10px;
			text-align: center;
			font-size: 20px;
			line-height: 24px;
		}
	}
	&.subcateg{
		display: flex;
		align-items: flex-start;

		.vitrine__produtos{

			@include media-breakpoint-only(md) {
				display: grid;
				grid-template-columns: repeat(3, calc((100% - 60px) / 3));
				grid-column-gap: 30px;
			}
		
			@include media-breakpoint-up(lg) {
				display: grid;
				grid-template-columns: repeat(3, calc((100% - 60px) / 3));
				grid-column-gap: 30px;
				width: 75%;
			}
		}
		@include media-breakpoint-down (md) {
			flex-direction: column;
		}
	}
}
.card__produto{
	width: 100%;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;

	&,
	a{
		display: flex;
		flex-direction: column;
	}

	a{
		flex-grow: 1;

		&:hover{
			text-decoration: none;

			.produto__foto:before{
				opacity: 1;
			}
		}
	}
}

.produto__foto{
	position: relative;


	&:before{
		content: '';
		width: 100%;
		height: 100%;
		background: url(../images/plus.png) rgba($vermelho,.25) center center no-repeat;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		transition: opacity .3s linear;
	}
}

.card__produto__body{
	padding: 10px;
	flex-grow: 1;
	background: $vermelho;
	color: #FFF;
}

.card__produto__nome{
	font-family: 'montserratbold', sans-serif;
	font-size: 18px;
}

#produtoDetalhes{
	.modal-dialog{
		max-width: 1110px;
	}

	.modal-content{
		&,
		.modal-body{
			border-radius: 0;
		}
	}

	.modal-body{
		&.loading{
			min-height: 200px;
			display: flex;
		}

		@include media-breakpoint-up(lg) {
			padding: 80px 60px;
		}
	}

	.nome__produto{
		font-family: 'montserratbold', sans-serif;
		font-size: 18px;
		margin-bottom: 20px;
	}
}
