.pagination{
	justify-content: flex-end;
}

.page-item{
	margin: 2px;

	&.active .page-link,
	.page-link:hover{
		border-color: $vermelho;
		background: #FFF;
		color: #333;
	}
}

.page-link{
	padding: 0.375rem .75rem;
	border-radius: 4px 4px 4px 4px;
	background: #FFF;
	border: 1px solid #CCC;
	color: #333;
	transition: all .3s linear;

}
