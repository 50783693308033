.section__empresa{
	overflow: hidden;
	width: 100%;
	overflow: hidden;
	padding-top: 80px;
	padding-bottom: 25px;

	hgroup{
		margin-bottom: 30px;

		.h1{
			font-size: 16px;
			color: #9e9e9e;
		}

		.h2{
			font-size: 30px;
			font-family: 'montserratbold', sans-serif;
		}
	}

	.content{
		width: 100%;
		max-width: 1920px;

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 45% 55%;
			grid-template-areas: 'IMAGE TEXT';
			position: relative;
			left: 50%;
			transform: translateX(-50%);

			.text__area{
				$col7: #{(map-get($container-max-widths, xl) / 12) * 7};
				grid-area: TEXT;
				width: 100%;
				max-width: $col7;
			}

			.image__area{
				grid-area: IMAGE;
				margin-left: auto;
			}
		}

		@include media-breakpoint-up(xl) {
			min-width: 1920px;
		}
	}

	.image__area,
	.text__area{
		padding-right: 15px;
		padding-left: 15px;
	}

	@include media-breakpoint-down(md) {
		.text__area{
			margin-bottom: 30px;
		}

		.image__area{
			text-align: center;
		}
	}
}


.tabs__container{
	filter: drop-shadow(0 0 8px rgba(#000,.4));
}

.nav__tabs{
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	overflow: auto;

	@include media-breakpoint-down(sm) {
		scrollbar-width: none;
		white-space: nowrap;
		padding-top: 10px;

		&::-webkit-scrollbar{
			display: none;
		}
	}

	li + li{
		margin-left: 4px;
	}
}

.nav__tabs__link{
	background: #000;
	color: #FFF;
	padding: 0.75rem 2rem;
	border-radius: 1rem 1rem 0 0;
	display: block;
	transition: all 0.3s linear;
	position: relative;

	&:hover{
		background: #333;
		text-decoration: none;
		color:#FFF;

		&:before{
			animation: gears 1s linear infinite;
		}
		&:after{
			animation: gears 1s linear infinite reverse;
		}
	}

	&.active,
	&.active:hover{
		background: $vermelho;


		&:after,
		&:before{
			@extend .fas;
			content: fa-content($fa-var-cog);
			position: absolute;
			color: #000;
			pointer-events: none;
		}

		&:after{
			font-size: 20px;
			right: -4px;
			top: 0;
		}

		&:before{
			top: -10px;
			right: 9px;
		}
	}
}

.tab__item{
	background: #FFF;
	padding: 30px;

	@include media-breakpoint-up(md) {
		border-radius: 0 40px 0 40px;
	}

	@include media-breakpoint-down(sm) {
		border-radius: 0 0 40px 40px;
	}

	&:not(.active){
		display: none;
	}

	&.active{
		animation: fadeIn 0.4s linear;
	}
}


.contadores{
	background: url(../images/bg-contadores.webp) $vermelho center top;
	color: #FFF;
	padding: 67px 0;
	background-attachment: fixed;

	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.contador{
	text-align: center;
	font-size: 24px;
	padding: 15px;
	min-width: 250px;
	max-width: 300px;
	width: 100%
}

.contador__number{
	font-size: 60px;
	font-family: 'montserratblack';
}


.section__clientes{
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;

	hgroup{
		margin-bottom: 30px;

		.h1{
			font-size: 16px;
			color: #9e9e9e;
		}

		.h2{
			font-size: 30px;
			font-family: 'montserratbold', sans-serif;
		}
	}

}

#owlClientes{
	padding-left: 48px;
	padding-right: 48px;

	.owl-prev,
	.owl-next{
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		margin-top: -24px;
		background: none;
		padding: 0;
		border: none;
		outline: none !important;

		&.disabled{
			opacity: .7;
			pointer-events: none;
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}
}


.wrap__section{
	width: 100%;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		.content{
			width: 1920px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: repeat(2, 50%);
		}

		.section__content{
			width: #{map-get($container-max-widths,xl) / 2};
		}

		.section__produtos{
			.section__content{
				margin-left: auto;
				padding-right: 50px;
			}
		}

		.section__orcamento{
			.section__content{
				margin-right: auto;
				padding-left: 50px;
			}
		}
	}

	.section__content{
		padding: 60px 15px;
	}

	.section__orcamento{
		color: #FFF;
		background-size: cover;
	}

	.form-control{
		border-radius: 18px;
		background-clip: unset;
	}

	textarea.form-control{
		min-height: 170px;
	}

	hgroup{
		margin-bottom: 30px;

		.h1{
			font-size: 16px;
			color: #9e9e9e;
		}

		.h2{
			font-size: 28px;
			font-family: 'montserratbold', sans-serif;
		}
	}

	.section__orcamento{
		h2{
			font-family: 'montserratregular', sans-serif;
			font-size: 30px;
			margin-bottom: 30px;

			&:before{
				content: '';
				width: 100px;
				height: 10px;
				background: $vermelho;
				display: block;
				margin-bottom: 10px;
			}
		}
	}

	.tns-nav{
		text-align: right;
		margin-bottom: 15px;

		button{
			display: inline-block;
			width: 18px;
			height: 18px;
			border: 2px solid $vermelho;
			background: transparent;
			border-radius: 50%;
			position: relative;
			outline: none !important;

			&:before{
				content:'';
				position: absolute;
				left: 2px;
				top: 2px;
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				border-radius: 50%;
				background: $vermelho;
				transform: scale(0);
			}

			& ~ button{
				margin-left: 4px;
			}

			&.tns-nav-active:before{
				transform: scale(1);
			}
		}
	}

	.slider__item{
		padding: 10px 0;
	}
}
