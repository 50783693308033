.btn-search-desktop{
	color: #9e9e9e;
	border-radius: 0;
	position: relative;
	perspective: 3px;
	border: none;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	transition: all .3s linear;
	box-shadow: none !important;

	& > * {
		pointer-events: none;
	}

	&:before{
		content:'';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $vermelho;
		z-index: -1;
		clip-path: circle(0 at 50% 50%);
		transition: clip-path .3s linear;
	}

	&:hover,
	&.active {
		color: color-contrast($vermelho);

		&:before{
			clip-path: circle(100% at 50% 50%);
		}
	}

}

.topo__main{
	.brand{
		img{
			@include img-fluid;
		}
	}
}


@include media-breakpoint-down(md) {
	.topo__main{
		position: fixed;
		left: 0;
		top: 0;
		width: 250px;
		height: 100%;
		background: #FFF;
		z-index: 1050;
		transition: transform 0.4s linear;
		transform: translateX(-250px);
		overflow: auto;

		&.topo__main--shown{
			transform: translateX(0);
		}

		.brand{
			padding: 30px 15px;
			text-align: center;
		}
	}
}



@include media-breakpoint-up(lg) {
	.topo__main{
		display: grid;
		grid-template-columns:
			170px calc(100% - 170px)
		;
		position: relative;
		perspective: 3px;

		.brand{
			padding: 10px 0;
		}

		.main__menu{
			justify-content: flex-end;
		}
	}

	.faixa__topo{
		background: #f1f1f1;

		.container{
			display: flex;
		}

		.dados__topo{
			font-size: 13px;
			margin-right: auto;
		}

		.dados__topo,
		.redes__sociais__topo{
			&,
			a{
				color: #9e9e9e;
			}

			a:hover{
				color: $vermelho;
				text-decoration: none;
			}
		}
	}
}
