.dados__topo{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	.fone a:before{
		@extend .fas,.fa-phone;
		transform: scale(-1,1);
	}

	.email a:before{
		@extend .fas,.fa-envelope;
	}

	.atendimento span:before{
		@extend .far, .fa-clock;
	}

	a,
	span{
		&:before{
			display: inline-block;
			margin-right: 10px;
		}
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		border-left: 1px solid #CCC;
		border-right: 1px solid #CCC;
		white-space: nowrap;

		li, a,span{
			display: flex;
		}

		li ~ li{
			border-left: 1px solid #CCC;
		}

		a,
		span{
			align-items: center;
			padding: 10px
		}
	}

	@include media-breakpoint-down(md) {
		border-top: 1px solid #CCC;

		li ~ li{
			border-top: 1px solid #CCC;
		}

		a,span{
			display: block;
			padding: 10px 15px;
		}

		a{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #383838;

			&:hover{
				color: $vermelho;
				text-decoration: none;
				background: #eee;
			}
		}
	}
}
