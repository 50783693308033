.redes__sociais__topo{

	&,
	a{
		display: flex;
	}

	a{
		justify-content: center;
		align-items: center;
		min-width: 48px;
		min-height: 48px;
	}

	@include media-breakpoint-up(lg) {
		border-left: 1px solid #CCC;

		a ~ a{
			border-left: 1px solid #CCC;
		}

		a{
			overflow: hidden;
			position: relative;
			perspective: 3px;
			transition: color .3s linear;

			&:after{
				content:'';
				position: absolute;
				width: 100%;
				height: 100%;
				background: $vermelho;
				left: 0;
				top: 0;
				z-index: -1;
				clip-path: circle(0 at 50% 50%);
				transition: clip-path .3s linear;
			}

			&:hover{
				color: #FFF !important;

				&:after{
					clip-path: circle(100% at 50% 50%);
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 15px;
		border-top: 1px solid #CCC;

		a{
			width: 48px;
			height: 48px;
			background: #383838;
			color: #FFF;
			border-radius: 50%;
			margin: 0 5px;
			font-size: 20px;
			transition: all .3s linear;

			&:hover{
				background: $vermelho;
				text-decoration: none;
				color: #FFF;
			}
		}
	}
}
