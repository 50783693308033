// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(50);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}

.loader{
	width: 60px;
	height: 60px;
	border: 5px solid $primary;
	border-radius: 50%;
	border-right-color: transparent;
	margin: auto;
	animation: loadingLazyElements 1s linear infinite;
}
