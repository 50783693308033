@include media-breakpoint-down(md) {
	.backdrop{
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1000;
		background: rgba(#000,.75);
		animation: fadeIn .4s linear;

		&.hide{
			animation: fadeOut .4s linear forwards;
		}
	}

	body.backdrop--shown{
		.mobile__controls,
		.wrapper,
		.rodape{
			filter: blur(4px)
		}
	}
}
