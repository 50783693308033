.rodape{
	background: $vermelho;
	color: #FFF;
	padding-top: 25px;
	font-size: 14px;

	a{
		color: #FFF;
	}

	.content__editable{
		margin-bottom: 30px;

		p{
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		text-align: center;

		.autor{
			margin-top: 10px;
		}
	}
}

.creditos{
	font-size: 12px;
	padding-bottom: 20px;

	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
	}
}

.direitos,
.autor{
	margin: 0 2px;
}
