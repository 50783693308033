#menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

@include media-breakpoint-up(lg) {
	.main__menu,
	#menu {
		display: flex;
		width: 100%;
	}

	#menu {
		justify-content: space-between;
		max-width: 730px;

		li,
		a{
			display: flex;
		}

		a{
			align-items: center;
			padding: 15px;
			position: relative;
			perspective: 3px;
			color: #383838;
			font-size: 16px;

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background: $vermelho;
				left: 0;
				top: 0;
				z-index: -1;
				clip-path: circle(0 at 50% 50%);
				transition: clip-path .3s linear;
			}
		}

		.active a,
		a:hover{
			color: #FFF;
			text-decoration: none;

			&:after{
				clip-path: circle(100% at 50% 50%)
			}
		}
	}
}

@include media-breakpoint-down(md) {
	#menu {
		border-top: 1px solid #CCC;

		a{
			display: block;
			padding: 10px 15px;
			color: #383838;

			&:hover{
				background: #EEE;
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: $vermelho;
			color: #FFF;
		}
	}
}
