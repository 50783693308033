.mobile__controls{
	background: #FFF;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 80px;
	box-shadow: 0 0 15px rgba(#868686,.8);
	border-bottom: 1px solid #868686;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	@include media-breakpoint-down(md) {
		display: grid;
		grid-template-columns: 50px calc(100% - 100px) 50px;
	}

	.brand{
		padding: 0 10px;
		margin: 0 auto;

		a{
			display: block;
			padding: 5px 0;
		}

		img{
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 70px;
			object-fit: contain;
		}
	}

	.btn{
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		padding: 0 0;
		width: 50px;
		min-height: 50px;

		.fas{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 38px;
			height: 38px;
			position: relative;
			perspective: 3px;

			&:after{
				content: '';
				position: absolute;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $vermelho;
				border-radius: 50%;
				left: 0;
				top: 0;
				transition: transform 0.15s linear;
				transform: scale(0);
			}
		}

		&.active,
		&.active:hover{
			color: #FFF;

			.fas:after{
				transform: scale(1);
			}
		}
	}
}
