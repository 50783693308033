.page__orcamento{
	flex-grow: 1;
	background-color: #2a2623;
	background-position: center center;
	background-size: cover;
	padding-top: 90px;
	padding-bottom: 90px;
	color: #FFF;

	.form-control{
		border-radius: 20px;
		min-height: 48px;
		background-clip: unset;
	}

	.message__validation{
		padding: 10px;
		margin-top: 10px;
		position: relative;
		border-radius: 4px;

		&:before{
			content:'';
			position: absolute;
			bottom: 100%;
			left: 15px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
		}
	}

	$validation: (
		'success': $success,
		'warning': $warning,
		'error': $vermelho
	);

	@each $type,$color in $validation{
		.has__#{$type} {
			.form-control{
				border-color: $color;
			}

			.message__validation{
				background: $color;
				color: color-contrast($color);
				animation: tada 1s linear;

				&:before{
					border-bottom-color: $color;
				}
			}
		}
	}

	textarea.form-control{
		min-height: 170px;
	}
}
