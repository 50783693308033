@import 'mobile__controls';
@import 'backdrop';
@import "topo__main";
@import "dados__topo";
@import "_redes__Sociais.scss";
@import "menu";
@import "search";


.topo{
	position: relative;

	@include media-breakpoint-up(lg) {
		border-bottom: 1px solid #868686;
	}

	@include media-breakpoint-down(md) {
		padding-top: 80px;
	}
}
