.search-desktop{
	position: absolute;
	top: var(--top, 50px);
	width: 100%;
	max-width: map-get($container-max-widths, xl);
	left: 50%;
	transform: translate(-50%,);
	z-index: 1000;

	&:not(.active) {
		form{
			clip-path: inset(0 0 0 100%);
		}
	}

	&.active{
		form{
			clip-path: inset(0 0 0 0);
		}
	}

	form{
		position: absolute;
		right: 15px;
		top: 7px;
		width: 300px;
		transition: all 0.4s linear
	}

	label{
		@include sr-only;
	}
}

.search{
	.form-group{
		position: relative;
	}

	.form-control,
	.btn{
		border-radius: 0;
		min-height: 48px;
	}

	.btn{
		right: 0;
		top: 0;
		position: absolute;
		width: 48px;
		z-index: 5;

		&:hover{
			color: $vermelho;
		}
	}

	.form-control{
		padding-right: 58px;
	}
}

.search-mobile{
	position: fixed;
	top: 90px;
	left: 50%;
	width: calc(100% - 30px);
	max-width: 600px;
	transform: translate(-50%, calc(-100% + -100px));
	z-index: 1050;
	transition: all .4s linear;
	color: #FFF;
	text-align: center;

	&.active{
		transform: translate(-50%, 0);
	}

	.form-control::placeholder{
		color: transparent
	}
}
