.section__depoimentos{
	padding: 60px 0;
	background: #dddddd;
	flex-grow: 1;
}


.depoimento{
	background: #FFF;
	position: relative;

	&:before{
		content:'';
		position: absolute;
		right: -5px;
		bottom: -5px;
		border-bottom: 5px dashed $vermelho;
		border-right: 5px dashed $vermelho;
		height: 50%;
		z-index: 0;
		pointer-events: none;
	}

	&:after{
		content: '';
		@extend .icon, .quots;
		position: absolute;
		right: 0;
		top: -30px;
	}

	@include media-breakpoint-up(md) {
		padding: 30px 90px;

		&:before{
			width: calc(100% - 90px)
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 30px;

		&:before{
			width: calc(100% - 30px)
		}
	}

	& ~ .depoimento{
		margin-top: 60px;
	}
}

.depoimento__body{
	margin-bottom: 10px
}

.depoimento__autor{
	font-family: 'montserratbold', sans-serif;
}

.depoimento__empresa{
	color: #575757
}
